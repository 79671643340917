<template>
  <div class="ban-save" id="ban-save">
    <div class="service">
      <div class="main">
        <div class="body">
          <div
            class="container"
            style="padding-top: 40px; margin-bottom: 30px; width: 500px"
          >
            <el-card>
              <span
                style="text-align: center; display: block; margin-bottom: 10px"
                >处置需求</span
              >
              <el-form
                :model="dataForm"
                status-icon
                :rules="dataRule"
                ref="ruleForm"
                ref="dataForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="联系人" prop="contacts">
                  <el-input
                    v-model="dataForm.contacts"
                    placeholder="联系人"
                  ></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="tel">
                  <el-input
                    v-model="dataForm.tel"
                    placeholder="电话"
                  ></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="enterprisename">
                  <el-input
                    v-model="dataForm.enterprisename"
                    placeholder="公司名称"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item label="物资类型" prop="targettypecascader_list">
                                <el-cascader
                                        v-model="dataForm.targettypecascader_list"
                                        :options="options"
                                        @change="handleChange"></el-cascader>
                            </el-form-item> -->
                <el-form-item label="物资名称" prop="targetname">
                  <el-input
                    v-model="dataForm.targetname"
                    placeholder="物资名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="物资价值" prop="price">
                  <el-input
                    v-model="dataForm.price"
                    placeholder="物资价值"
                  ></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="submitForm()"
                    >提交</el-button
                  >
                  <el-button @click="resetForm()">重置</el-button>
                </el-form-item>
              </el-form>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    let reg =
      /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
    var validatorPhone = (phone, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!reg.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      dataForm: {
        contacts: "",
        tel: "",
        enterprisename: "",
        targettype: "",
        targettypecascader: "",
        targettypecascader_list: [],
        targetname: "",
        price: "",
      },
      options: [
        {
          value: "11",
          label: "废旧物资",
          children: [
            {
              value: "a",
              label: "综合类",
            },
            {
              value: "b",
              label: "废钢",
            },
            {
              value: "c",
              label: "废铁",
            },
            {
              value: "d",
              label: "废铜",
            },
            {
              value: "e",
              label: "橡胶",
            },
            {
              value: "f",
              label: "其他",
            },
          ],
        },
        {
          value: "22",
          label: "闲废设备",
        },
        {
          value: "33",
          label: "闲废车辆",
        },
        {
          value: "44",
          label: "工业固废",
        },
        {
          value: "66",
          label: "租赁",
        },
        {
          value: "55",
          label: "其他",
        },
      ],
      dataRule: {
        contacts: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
        tel: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
          { validator: validatorPhone, trigger: "blur" },
        ],
        enterprisename: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],
        targetname: [
          { required: true, message: "物资名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    // dataRule() {
    //   // var checktarget = (rule, value, callback) => {
    //   //     if (value === '') {
    //   //         callback(new Error('请选择标的物类型'))
    //   //     }  else {
    //   //         callback()
    //   //     }
    //   // }
    //   return {
    //     contacts: [
    //       { required: true, message: "联系人不能为空", trigger: "blur" },
    //     ],
    //     tel: [{ required: true, message: "联系电话不能为空", trigger: "blur" }],
    //     enterprisename: [
    //       { required: true, message: "企业名称不能为空", trigger: "blur" },
    //     ],
    //     // targettypecascader_list: [
    //     //     // {required: true, validator: checktarget, trigger: 'blur'}
    //     //     { required: true, message: '物资类型不能为空', trigger: 'blur' }
    //     // ],
    //     targetname: [
    //       { required: true, message: "物资名称不能为空", trigger: "blur" },
    //     ],
    //   };
    // },
  },
  mounted() {
    if (this.$route.query.id) this.biddingFlag = this.$route.query.id;
  },
  methods: {
    ...mapGetters(["getUser", "getToken"]),
    handleChange(value) {},
    submitForm() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.dataForm.targettypecascader =
          this.dataForm.targettypecascader_list.join(",");
        this.$post("/shoppingui/bidShopping/saveManagement", this.dataForm)
          .then((data) => {
            if (data.code !== 0) {
              this.$message({
                message: "保存失败",
                offset: 130,
                type: "error",
                showClose: "true",
              });
              return false;
            }
            this.$message({
              message: "保存成功",
              offset: 130,
              type: "success",
              showClose: "true",
              onClose: () => {
                this.resetForm();
              },
            });
          })
          .catch(() => {});
      });
    },
    resetForm() {
      this.$refs["dataForm"].resetFields();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/index.css";
@import "../assets/css/page/service.scss";
.gotoDetails {
  cursor: pointer;
}
</style>
